<template>
    <div
        v-show="visible"
        v-loading="$wait.is('loading.' + employeeProp.uuid)"
        class="card employee-week-plus-container"
        :class="{
            'address-hidden': !addressVisible,
            'city-hidden': !cityVisible,
            'client-hidden': !clientVisible,
            'employees-hidden': !employeesVisible,
            'services-hidden': !servicesVisible,
            'note-hidden': !noteVisible,
        }"
        style="min-height: 400px"
        element-loading-spinner="el-custom-spinner"
    >
        <div class="flex justify-between w-full absolute left-0 px-4">
            <router-link :to="`/employees/${employeeProp.uuid}/information`">
                <p class="hover:text-red-500 hover:font-semibold">
                    {{ employeeProp.name }} {{ employeeProp.surname }}
                </p>
            </router-link>
            <div class="flex">
                <p
                    class="flex justify-center items-center h-6 w-6 rounded-full bg-green-400
                    text-white shadow-md transition hover:bg-green-500 cursor-pointer"
                    @click="goToToday"
                >
                    <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21 8.77217L14.0208 1.79299C12.8492 0.621414 10.9497 0.621413 9.77817 1.79299L3 8.57116V23.0858H10V17.0858C10 15.9812
                            10.8954 15.0858 12 15.0858C13.1046 15.0858 14 15.9812 14 17.0858V23.0858H21V8.77217ZM11.1924 3.2072L5
                            9.39959V21.0858H8V17.0858C8 14.8767 9.79086 13.0858 12 13.0858C14.2091 13.0858 16 14.8767 16
                            17.0858V21.0858H19V9.6006L12.6066 3.2072C12.2161 2.81668 11.5829 2.81668 11.1924 3.2072Z"
                            fill="currentColor"
                        />
                    </svg>
                </p>
                <p
                    class="flex justify-center items-center h-6 w-6 ml-1 rounded-full bg-green-400
                    text-white shadow-md transition hover:bg-green-500 cursor-pointer"
                    @click="goToPrevWeek"
                >
                    <svg width="20" height="20" style="margin-left:-2px;" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"
                            fill="currentColor"
                        />
                    </svg>
                </p>
                <p
                    class="flex justify-center items-center h-6 w-6 ml-1 rounded-full bg-green-400
                    text-white shadow-md transition hover:bg-green-500 cursor-pointer"
                    @click="goToNextWeek"
                >
                    <svg width="20" height="20" style="margin-left:-2px;" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z" fill="currentColor" />
                    </svg>
                </p>
            </div>
        </div>

        <FullCalendar
            v-if="visibilityHack"
            ref="calendarEWP"
            :options="calendarOptions"
        >
            <template #eventContent="event">
                <EventContent v-if=" ! event.event.allDay && ! event.event.extendedProps.pause" :calendarEvent="event" :vue="vue" />
                <EventContentAllDay v-if="event.event.allDay" :calendarEvent="event" />
            </template>
        </FullCalendar>
    </div>
</template>
<script>
import { mapState } from 'vuex';

import queryString from 'query-string';
import FullCalendar from '@fullcalendar/vue';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import svLocale from '@fullcalendar/core/locales/sv';
import Cookies from 'js-cookie';
import EventContent from './EventContent.vue';
import EventContentAllDay from './EventContentAllDay.vue';

export default {
    components: {
        FullCalendar,
        EventContent,
        EventContentAllDay,
    },

    props: {
        employeeProp: {
            type:    Object,
            default: () => {},
        },
        data: {
            type:    Object,
            default: () => {},
        },
    },

    data() {
        return {
            vue:              this,
            visible:          true,
            externalEvents:   true,
            visibilityHack:   true,
            addressVisible:   Cookies.get('week_per_employee_plus_address_visible') !== 'false',
            cityVisible:      Cookies.get('week_per_employee_plus_city_visible') !== 'false',
            clientVisible:    Cookies.get('week_per_employee_plus_client_visible') !== 'false',
            employeesVisible: Cookies.get('week_per_employee_plus_employees_visible') !== 'false',
            servicesVisible:  Cookies.get('week_per_employee_plus_services_visible') !== 'false',
            noteVisible:      Cookies.get('week_per_employee_plus_note_visible') !== 'false',
            calendarOptions:  {
                plugins:            [timeGridPlugin, interactionPlugin],
                initialView:        'timeGridWeek',
                events:             this.getAllEvents,
                hiddenDays:         this.$store.state.calendar.weekDaysHidden,
                locale:             svLocale,
                contentHeight:      'auto',
                nowIndicator:       'true',
                allDaySlot:         false,
                firstDay:           this.$dayjs().day(),
                headerToolbar:      false,
                weekNumbers:        false,
                slotDuration:       '00:30:00',
                slotMinTime:        '06:00:00',
                slotMaxTime:        '22:00:00',
                eventClick:         this.eventClick,
                eventSourceSuccess: this.eventSourceSuccess,
                navLinkDayClick:    this.navLinkDayClick,
                fixedWeekCount:     false,
                navLinks:           true,
                editable:           true,
                eventDrop:          this.eventDrop,
                // @Filip
                headerToolbar:      {
                    left:   '',
                    center: 'title',
                    right:  '',
                },
                // titleFormat: 'D MMMM (v.w)',
                titleFormat: dateObject => {
                    const start = this.$dayjs(dateObject.start.marker);
                    const end = this.$dayjs(dateObject.end.marker);
                    return start.format('D MMMM (v.w) - ') + end.format('D MMMM (v.w)');
                },
            },
        };
    },

    computed: {
        ...mapState('calendar', {
            weekEventsData:      state => state.weekEventsData,
            generalWeekSelected: state => state.generalWeekSelected,
            weekDaysHidden:      state => state.weekDaysHidden,
        }),

        dataComp() {
            return this.data;
        },
    },

    watch: {
        generalWeekSelected() {
            this.$wait.start(`loading.${this.employeeProp.uuid}`);
        },
        weekEventsData() {
            this.externalEvents = true;
            this.$refs.calendarEWP.getApi().gotoDate(this.generalWeekSelected.format('YYYY-MM-DD'));
        },
        weekDaysHidden() {
            this.externalEvents = true;
            this.calendarOptions.hiddenDays = this.weekDaysHidden;
            this.visibilityHack = false;
            this.$nextTick(() => {
                this.visibilityHack = true;
            });
        },

        dataComp() {
            this.changeCalendarSettings(this.dataComp);
        },
    },

    methods: {
        async getAllEvents(info) {
            this.$wait.start(`loading.${this.employeeProp.uuid}`);

            if (!this.checkVisibility()) return false;

            if (this.externalEvents) {
                this.externalEvents = false;
                return this.weekEventsData[this.employeeProp.uuid].events;
            }

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/calendar`, {
                params: {
                    from:           info.start.valueOf(),
                    to:             info.end.valueOf(),
                    employees_uuid: [this.employeeProp.uuid],
                    cancelled:      this.$store.state.calendar.filterCancelled ? 1 : 0,
                },
            });

            return data;
        },

        navLinkDayClick(date) {
            this.$store.commit('calendar/showAddModal', {
                startDateSelected:   this.$dayjs(date),
                preSelectedEmployee: [this.employeeProp.uuid],
            });
        },

        eventSourceSuccess() {
            this.$wait.end(`loading.${this.employeeProp.uuid}`);
        },

        async eventDrop(info) {
            this.$wait.start(`loading.${this.employeeProp.uuid}`);
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/${info.event.extendedProps.uuid}/update_date`, {
                delta: info.delta.days,
            });
            this.$refs.calendarEWP.getApi().refetchEvents();
            this.$notify.success({ title: this.$t('common.success') });
            this.$wait.end(`loading.${this.employeeProp.uuid}`);
        },

        goToToday() {
            this.$refs.calendarEWP.getApi().today();
        },

        goToPrevWeek() {
            this.$refs.calendarEWP.getApi().prev();
        },

        goToNextWeek() {
            this.$refs.calendarEWP.getApi().next();
        },

        checkVisibility() {
            const query = queryString.parse(window.location.search, { sort: false });
            // @Filip
            query.employee = Array.isArray(query.employee) ? query.employee : (query.employee ? [query.employee] : []);

            if (query.employee.length && !query.employee.includes(this.employeeProp.uuid)) {
                this.visible = false;
                return false;
            }

            this.visible = true;
            return true;
        },

        changeCalendarSettings(event) {
            this.addressVisible = event.addressVisible;
            this.cityVisible = event.cityVisible;
            this.clientVisible = event.clientVisible;
            this.employeesVisible = event.employeesVisible;
            this.servicesVisible = event.servicesVisible;
            this.noteVisible = event.noteVisible;
            this.$nextTick(() => {
                this.$refs.calendarEWP.getApi().refetchEvents();
            });
        },
    },
};
</script>
<style lang='scss'>
    @import './EmployeeWeekPlusView.scss';
</style>
