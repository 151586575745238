<template>
    <div class="flex flex-col text-white" :style="{ backgroundColor: backgroundColor }">
        <span v-if="calendarEvent.event.extendedProps.cancelled" class="canceled-bg">
            <svg fill="currentColor" viewBox="0 0 20 20" class="w-24 h-24">
                <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414
                    1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                />
            </svg>
        </span>

        <div class="fc-top-bar flex items-center">
            <span class="top-icons">
                <fa-icon :icon="['fas', 'pencil']" class="cursor-pointer" @click="showEditModal" />
                <fa-icon v-if="calendarEvent.event.extendedProps.confirmation" :icon="['fas', 'minus-circle']" />
                <fa-icon v-else :icon="['fas', 'check-circle']" />
                <fa-icon v-if="calendarEvent.event.extendedProps.note" :icon="['fas', 'info-circle']" />
                <fa-icon class="cursor-move" :icon="['far', 'arrows']" />
                <fa-icon v-if="calendarEvent.event.extendedProps.keys" :icon="['fas', 'key']" />
            </span>

            <span class="fc-time">
                {{ startTime }} - {{ endTime }}
            </span>
        </div>

        <span class="fc-client" :title="calendarEvent.event.title">
            <a :href="`/clients/${clientUuid}/dashboard`" class="hover:font-bold" @click.prevent="goToClient">
                <fa-icon :icon="['fas', 'user-circle']" />
                {{ calendarEvent.event.title }}
            </a>
        </span>

        <span class="fc-address" :title="calendarEvent.event.extendedProps.street">
            <fa-icon :icon="['fas', 'map-marker']" />
            {{ calendarEvent.event.extendedProps.street }}
        </span>

        <span class="fc-city">
            <fa-icon :icon="['fas', 'map-marker']" />
            {{ calendarEvent.event.extendedProps.city }}
        </span>

        <div v-if="calendarEvent.event.extendedProps.employees" class="fc-employee">
            <a
                v-for="employee in calendarEvent.event.extendedProps.employees"
                :key="employee.uuid"
                :title="employee.name"
                :href="`/employees/${employee.uuid}/information`"
                class="hover:font-bold"
                @click.prevent="goToEmployee(employee.uuid)"
            >
                <fa-icon :icon="['fas', 'user']" />
                {{ employee.name }}
            </a>
        </div>

        <div v-if="calendarEvent.event.extendedProps.services" class="fc-services">
            <a
                v-for="service in calendarEvent.event.extendedProps.services"
                :key="service"
                :href="`/services`"
                :title="service"
                class="hover:font-bold"
                @click.prevent="goToServices"
            >
                {{ service }}
            </a>
        </div>

        <span v-if="calendarEvent.event.extendedProps.note" class="fc-note">
            {{ calendarEvent.event.extendedProps.note }}
        </span>
    </div>
</template>
<script>


export default {
    props: ['calendarEvent', 'vue'],

    computed: {
        clientUuid() {
            return this.calendarEvent.event.extendedProps.client_uuid;
        },

        startTime() {
            return this.$dayjs(this.calendarEvent.event.start).format('HH:mm');
        },

        endTime() {
            return this.$dayjs(this.calendarEvent.event.end).format('HH:mm');
        },

        backgroundColor() {
            return this.calendarEvent.backgroundColor !== 'null' ? this.calendarEvent.backgroundColor : '#20a0ff';
        },
    },

    methods: {
        // @Filip props modification
        goToClient() {
            this.vue.$router.push({ path: `/clients/${this.clientUuid}/dashboard` });
        },
        goToEmployee(employeeUuid) {
            this.vue.$router.push({ path: `/employees/${employeeUuid}/information` });
        },
        goToServices() {
            this.vue.$router.push({ name: 'services'});
        },
        showEditModal() {
            this.vue.$store.commit('calendar/showEditNewModal', this.calendarEvent.event.extendedProps.uuid);
            // this.vue.$store.commit('calendar/showEditModal', {
            //     event_uuid: this.calendarEvent.event.extendedProps.uuid,
            // });
        },
    },
};
</script>
